<template>
    <uw-content title="任务管理" padding="10px" v-loading="$store.state.loading">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button :type="post.search.status == null ? 'primary' : 'text'" @click="post.search.status = null; TableSearch()">全部</el-button>
            <el-button :type="post.search.status == 0 ? 'primary' : 'text'" @click="post.search.status = 0; TableSearch()">未开始</el-button>
            <el-button :type="post.search.status == 1 ? 'primary' : 'text'" @click="post.search.status = 1; TableSearch()">进行中</el-button>
            <el-button :type="post.search.status == 5 ? 'primary' : 'text'" @click="post.search.status = 5; TableSearch()">已完成</el-button>
            <el-button :type="post.search.status == 4 ? 'primary' : 'text'" @click="post.search.status = 4; TableSearch()">已关闭</el-button>
            <el-button :type="post.search.status == 2 ? 'warning' : 'text'" @click="post.search.status = 2; TableSearch()">已暂停</el-button>
            <el-button :type="post.search.status == 6 ? 'warning' : 'text'" @click="post.search.status = 6; TableSearch()">已取消</el-button>
        </template>

        <!-- 内容 -->
        <div :style="{ height: '100%', display: 'flex', flexDirection: 'column' }">

            <!-- toolbar -->
            <uw-grid-search expand gutter="0 10px" :column="4" :style="{ margin: '20px 0' }">

                <!-- tools -->
                <template slot="tools">
                    <el-dropdown trigger="click">
                        <el-button type="primary">批量管理<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native.stop="TaskDeleteMultiple()">批量删除</el-dropdown-item>
                            <el-dropdown-item @click.native.stop="TaskFlowStartMultiple()" divided>批量开始</el-dropdown-item>
                            <el-dropdown-item @click.native.stop="TaskFlowSuspendMultiple()">批量暂停</el-dropdown-item>
                            <el-dropdown-item @click.native.stop="TaskFlowCancelMultiple()">批量取消</el-dropdown-item>
                            <el-dropdown-item @click.native.stop="TaskFlowCloseMultiple()">批量关闭</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <files-export-xls-dropdown model="pm_task" :search="post.search" :style="{ marginLeft: '5px', float: 'right' }" />
                </template>

                <!-- full -->
                <template slot="full">
                    <el-input v-model="post.search.name" @input="$lodash.debounce(TableSearch, 500)" clearable placeholder="任务名称" />
                </template>

                <!-- 高级检索 -->
                <el-form-item label="关联项目">
                    <pm-projects-select v-model="post.search.project_id" @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="里程碑 / 项目阶段">
                    <pm-milestone-select @onChange="TableSearch()" v-model="post.search.milestone_id" :projects_id="post.search.project_id" />
                </el-form-item>
                <el-form-item label="指派给">
                    <users-select v-model="post.search.assign_now_users_ids" multiple @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="参与者">
                    <users-select v-model="post.search.assign" multiple @onChange="TableSearch" />
                </el-form-item>
                <el-form-item label="是否延期">
                    <el-select v-model="post.search.is_overdue" @change="TableSearch()" clearable filterable :style="{ width: '100%' }">
                        <el-option :value="0" label="未延期" />
                        <el-option :value="1" label="已延期" />
                    </el-select>
                </el-form-item>
                <el-form-item label="计划日期">
                    <el-date-picker
                        :style="{ width: '100%' }"
                        v-model="post.search.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        @change="TableSearch" />
                </el-form-item>

            </uw-grid-search>

            <!-- 表格 -->
            <div :style="{ flex: '1 0 0', height: '0', overflow: 'hidden' }">
                <vxe-table ref="xTable" :data="table" height="100%">

                    <!-- 复选框 -->
                    <vxe-column type="checkbox" width="45" fixed="left"></vxe-column>

                    <!-- 管理项目 -->
                    <vxe-column min-width="240" field="project" title="项目名称">
                        <template #default="{ row }">
                            <el-span type="primary" @onClick="$refs.projectsDrawer.Open(row.projects.id)" :title="row.projects.number + ' - ' + row.projects.name" />
                        </template>
                    </vxe-column>

                    <!-- 任务名称 -->
                    <vxe-column min-width="300" title="任务名称">
                        <template #default="{ row }">
                            <!-- 重要程度 -->
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 1" effect="plain" type="danger">P1</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 2" effect="plain" type="warning">P2</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 3" effect="plain" type="primary">P3</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.p == 4" effect="plain" type="success">P4</el-tag>
                            <!-- 状态判断 -->
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 0" effect="dark" type="primary">未开始</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 1" effect="dark" type="primary">进行中</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 2" effect="dark" type="warning">已暂停</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 4" effect="dark" type="info">已关闭</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 5" effect="dark" type="success">已完成</el-tag>
                            <el-tag :style="{ marginRight: '5px' }" v-if="row.status == 6" effect="dark" type="info">已取消</el-tag>
                            <!-- 延期判断 -->
                            <el-tag v-if="row.is_overdue == 1" effect="dark" type="danger">已延期</el-tag>
                            <!-- 项目名称 -->
                            <el-span type="primary" :style="{ color: row.name_color }" @click.native="$refs.taskIndex.Open(row.id)" :title="row.name" />
                        </template>
                    </vxe-column>

                    <!-- 里程碑/项目阶段 -->
                    <vxe-column min-width="150" field="name" title="里程碑/项目阶段">
                        <template #default="{ row }">
                            {{ row.milestone?.name ?? '-' }}
                        </template>
                    </vxe-column>

                    <!-- 任务类型 -->
                    <vxe-column width="120" field="type" title="任务类型" />

                    <!-- 指派给 -->
                    <vxe-column width="110" title="指派给">
                        <template #default="{ row }">
                            <div style="display: flex; align-items: center; justify-content: flex-start;">
                                <el-avatar style="margin-right: 5px;" :size="26" :src="row.now_assign_users?.avatar"> {{ row.now_assign_users?.name[0] }} </el-avatar>
                                {{  row.now_assign_users?.name }}
                            </div>
                        </template>
                    </vxe-column>

                    <!-- 参与人员 -->
                    <vxe-column width="80" field="assign" title="参与人员" :visible="true">
                        <template #default="{ row }">
                            <el-tooltip placement="top" :content="row.assign_user_name.toString()">
                                <el-link icon="el-icon-user">{{ row.assign.length }}</el-link>
                            </el-tooltip>
                        </template>
                    </vxe-column>

                    <!-- 概要 -->
                    <vxe-column min-width="90" field="consume_days" title="任务消耗/P"></vxe-column>
                    <vxe-column min-width="90" field="wo_sum_day_consume" title="工单消耗/P"></vxe-column>
                    <vxe-column min-width="70" field="working_days" title="预期/P"></vxe-column>
                    <vxe-column min-width="70" title="小计/P">
                        <template #default="{ row }">
                            {{ Number(row.consume_days) + Number(row.wo_sum_day_consume) }}
                        </template>
                    </vxe-column>

                    <!-- 时间 -->
                    <vxe-column width="90" field="start_date[0]" title="开始时间"></vxe-column>
                    <vxe-column width="90" field="start_date[1]" title="截止时间"></vxe-column>

                </vxe-table>
            </div>

            <!-- 分页 -->
            <div :style="{ marginTop: '10px' }">
                <vxe-pager
                    :total="post.page.total"
                    :page-size.sync="post.page.size"
                    :current-page.sync="post.page.current"
                    @page-change="TableSearch()">
                </vxe-pager>
            </div>

        </div>

        <!-- 组件 -->
        <pm-projects-drawer ref="projectsDrawer" @onChange="TableSearch" />
        <pm-projects-task-drawer ref="taskIndex" @onChange="TableSearch" />

    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            table: [],

            post: {
                page: {
                    total: 0,
                    size: 20,
                    current: 1,
                },
                search: {
                    project_id: null,
                    now_assign: null,
                    assign_now_users_ids: [],
                    assign: [],
                    status: null,
                    is_overdue: null,
                    date: null,
                    name: null
                },
                order: {
                    method: 'desc',
                    column: 'id'
                },
                append: [ 'now_assign_users', 'milestone', 'wo_sum_day_consume', 'projects', 'type', 'assign_user_name', 'now_assign_user_name' ],
                hidden: [ 'describe' ],
            }
        }
    },
    async mounted () {
        this.TableSearch()
    },

    methods: {

        // 查询
        TableSearch()
        {
            this.$http.post( '/9api/pm/task/search',  this.post).then(rps => {
                
                // 更新数据
                this.post.page.total = rps.data.total
                this.table = rps.data.body

                // 页码溢出
                if (this.table.length == 0 && this.post.page.current != 1) {
                    this.post.page.current = 1
                    this.TableSearch()
                }
            })
        },

        /**
        |--------------------------------------------------
        | 项目任务 - 移除
        |--------------------------------------------------
        |
        */

        TaskDelete(ids) {
            this.$confirm('此操作将永久删除数据，以及关联的数据，包括且不仅限以下内容: 任务工时、任务附件、子任务、统计数据等', '请谨慎操作', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'error',
                center: true
            }).then(() => {
                this.$http.post('/9api/projects-task/delete', { ids: ids }).then(rps => {
                    this.TableSearch()
                })
            })
        },

        TaskDeleteMultiple() {
            this.TaskDelete(this.$refs.xTable.getCheckboxRecords().map(item => item.id))
        },

        /**
        |--------------------------------------------------
        | 批量操作
        |--------------------------------------------------
        |
        */

        TaskFlowStartMultiple() {
            this.$http.post('/9api/projects-task/flow-start-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowSuspendMultiple() {
            this.$http.post('/9api/projects-task/flow-suspend-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowCancelMultiple() {
            this.$http.post('/9api/projects-task/flow-cancel-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        },

        TaskFlowCloseMultiple() {
            this.$http.post('/9api/projects-task/flow-close-multiple', { ids: this.$refs.xTable.getCheckboxRecords().map(item => item.id)}).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>
